import { styled } from "styled-components";
import { colors } from "../../../styles";
import { Swiper } from "swiper/react";

export const SectionWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${colors.whiteBlue};
  position: relative;
  z-index: 2;
`;

export const MarginWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 140px 120px;

  @media (max-width: 767px) {
    display: flex;
    padding: 60px 24px;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 400px) {
    align-items: flex-start;
  }
`;

export const ContentBox = styled.div`
  display: grid;
  grid-template-columns: 600px 1fr 1fr;

  @media (max-width: 900px) {
    grid-template-columns: 300px 1fr 1fr;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 25px;
  }
`;
export const ItemBox = styled.div`
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
`;

export const ImageBox = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 20px;
  background-color: ${colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
  }
`;

export const ItemImage = styled.img`
  width: 100px;
  height: 100px;
  @media (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
`;

export const ContentTitle = styled.div`
  width: 166px;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    width: auto;
  }
`;

export const BusinessTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999;

  @media (max-width: 767px) {
    padding: 0 24px;
  }
`;
export const BusinessText = styled.li`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.01em;
  text-align: left;

  color: ${colors.darkGray};
  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

export const TextBox = styled.div`
  display: flex;
  padding-top: 120px;
  @media (max-width: 767px) {
    position: relative;
    right: 38px;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const ListTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;

  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

export const StyleSwiper = styled(Swiper)`
  position: absolute;
  right: -30%;
  bottom: 13%;
  z-index: 1;
  .swiper-wrapper {
    width: 100vw;

    @media (max-width: 767px) {
      width: 100vw;
    }
  }
  .swiper-slide {
    width: 210px !important;
    margin: 0 10px !important;
    @media (max-width: 767px) {
      width: 170px !important;
      margin: 0 8px !important;
    }
  }

  @media (max-width: 767px) {
    position: relative;
    padding-top: 48px;
    left: 0;
    right: 300px;
  }
`;

export const Crape = styled.div<{
  image: string;
}>`
  width: 210px;
  height: 297px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 767px) {
    width: 170px;
    height: 240px;
  }
`;

export const OpacityBox1 = styled.div`
  width: 400px;
  height: 300px;
  position: absolute;
  z-index: 2;
  left: 38%;
  bottom: 150px;
  background: linear-gradient(90deg, #f7f7fd 0%, rgb(247, 247, 253, 0) 100%);
`;
export const OpacityBox2 = styled.div`
  width: 700px;
  height: 300px;
  position: absolute;
  z-index: 2;
  right: 0%;
  bottom: 150px;
  background: linear-gradient(260deg, #f7f7fd 0%, rgb(247, 247, 253, 0) 100%);
`;

export const WordBreak = styled.div`
  padding-left: 20px;
`;
